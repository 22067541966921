<template>
  <main class="page-static" :class="{_loading: pending}">
    <BasePreloader v-if="pending"/>
    <section class="static-content container">
      <div class="static-content__inner container-inner">
        <h1 class="static-content__title">{{ textTitle }}</h1>
        <div class="text" v-html="textContent"/>
      </div>
    </section>
  </main>
</template>

<script>
  import {http} from '@/axios'

  export default {
    name: 'Loyalty',
    data() {
      return {
        textContent: null,
        textTitle: null,
        pending: true
      }
    },
    methods: {
      load() {
        const {pageId} = this.$route.meta
        this.pending = true
        http.get(`/api/page/${pageId}`)
            .then(({data}) => {
              this.textContent = data.item.description_first
              this.textTitle = data.item.title
            }).finally(() => {
          this.pending = false
        })
      },
    },
    created() {
      this.load()
    }
  }
</script>

<style>

</style>